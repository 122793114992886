/* start Password strength bar style */
ul#strengthBar {
    display: inline;
    list-style: none;
    margin: 0;
    margin-left: 15px;
    padding: 0;
    vertical-align: 2px;
}

.point:last {
    margin: 0 !important;
}

.point {
    background: #DDD;
    border-radius: 2px;
    display: inline-block;
    height: 5px;
    margin-right: 1px;
    width: 20px;
}

#content{
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
}

/* end Password strength bar style */
